/* In ./css/animations.css */
.slide-up-enter {
    opacity: 0;
    transform: translateY(20px);
}

.slide-up-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.slide-up-exit {
    opacity: 1;
    transform: translateY(0);
}

.slide-up-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Transparan hitam */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* Pastikan overlay berada di atas konten lainnya */
}

.loading {
    color: white;
    font-size: 24px; /* Ukuran teks loading */
}
  
.modal-fullscreen .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border-radius: 0;
}

.wrapper {
  display: flex;
  transition: all 0.3s ease;
}

.sidebar {
  width: 250px;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  position: fixed;
  height: 100vh;
  background-color: #343a40;
  color: #fff;
}

.sidebar.closed {
  transform: translateX(-110%);
}

.main {
  flex: 1;
  margin-left: 260px; /* offset for the sidebar */
  transition: margin-left 0.3s ease;
  padding: 0px 0px 0px 0px;
}

.sidebar-closed .main {
  margin-left: 0;
}

.tooltip {
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000; /* Pastikan tooltip berada di atas elemen lainnya */
  transition: opacity 0.3s ease-in-out;
}
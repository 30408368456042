/* styles.css */
.custom-button {
    background-color: #BD0951;
    border: 0px solid black;
    width: 100%;
    padding: 15px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #FF5733;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Transparan hitam */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* Pastikan overlay berada di atas konten lainnya */
}

.loading {
    color: white;
    font-size: 24px; /* Ukuran teks loading */
}
  
/* customTableStyles.css */

/* Custom styles for the table */
.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  padding: 12px;
  text-align: left;
}

.custom-table th {
  background-color: #f8f9fa;
  color: #343a40;
  font-weight: bold;
  position: relative;
}

.custom-table td {
  border-bottom: 1px solid #dee2e6;
}

.custom-table th.sorted-asc::after,
.custom-table th.sorted-desc::after {
  content: '';
  position: absolute;
  right: 0.75em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75em;
  color: grey; /* Change arrow color to grey */
}

.custom-table th.sorted-asc::after {
  content: '▲'; /* Unicode character for up arrow */
}

.custom-table th.sorted-desc::after {
  content: '▼'; /* Unicode character for down arrow */
}

/* Custom filter input style */
.custom-filter-input {
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}

/* Pagination styles */
.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.custom-pagination .btn {
  margin: 0 0.25rem;
}

.custom-pagination .form-select {
  width: auto;
}

/* Additional styling for filters */
.custom-filter-container {
  margin-bottom: 1rem;
}
